

/* We set the size of the picture */
.team-list .team-image {
    height:100px;
    width: 100px;
    padding-top:5px;
    padding-bottom:5px;
    margin-right: 30px;
    flex: 0 0 auto;
}

/* We set the size of the picture */
.team-list .team-image > a {
    height:100%;
}

/* We set the size of the picture */
.team-list .team-image img {
    width: auto;
    height: 100%;
    margin-left:auto;
    margin-right:auto;
}

/* We set shadow and margin for product list item */
.team-list {
    margin: 0 0 40px 0;
    padding: 0;
    width: 100%;
}

/* We ensure that picture is verticaly aligned with content */
.team-list.grid-inner {
    display:flex;
    align-items: center;
}

/* We ensure that picture and content wrap for mobile */
/* picture takes full width and with small margin bottom */
@media (max-width: 767.98px) {
.team-list .team-image {
        width: 100%;
        margin-bottom: 20px;
}
.team-list.grid-inner {
      flex-wrap: wrap;
}
}

/* We display the content next to the picture */
.team-list .team-desc {
    padding-top:5px;
    padding-right:10px;
    padding-bottom: 0px;

    flex: 0 1 auto;
    align-self: stretch;

    display: flex;
    flex-direction: column;
}
@media (max-width: 767.98px) {
.team-list .team-desc {
      padding-left:10px;
}
}
.team-list .team-desc .team-title {
    margin-bottom: 10px;
}
.team-group .team-image > div {
    position:relative;
    height:100%;
    overflow:hidden;
}
.team-group .team-image > div > img {
    transition: opacity 0.5s;
    height:100%;
    width:auto;
    min-width:100%;
    max-width:none;
    margin-left:50%;
    transform: translate(-50%, 0);
}
.team-group .team-image > div > img:nth-child(2) {
    position:absolute;
    top:0px;
    left:0px;
}
.team-group .team-image:hover > div > img:nth-child(2) {
    opacity: 0;
}

